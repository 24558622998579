.demo-wrapper {
  color: black;
}

.demo-editor {
  background-color: white;
  color: black;
  padding: 0 20px;
  height: 500px;
}

.placeholder-ul{
  visibility: hidden;
}
.placeholder-li:hover {
  background: #F1F1F1;
}